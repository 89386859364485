import { nanoid } from 'nanoid';

import {
  notificationsDataSelector,
  fistNotificationIdSelector,
} from 'redux/notifications/selectors';

import { NOTIFICATION_SET, NOTIFICATION_UNSET } from './constants';

const DURATION_NOTIFICATION = 4000;
const MAX_NOTIFICATION = 5;

export const unsetNotification = payload => async dispatch => {
  const { notificationId } = payload;
  dispatch({ type: NOTIFICATION_UNSET, payload: { notificationId } });
};

export const setNotification = payload => async (dispatch, getState) => {
  const {
    title,
    type,
    content,
    isImportant = false,
    autoClose = true,
    duration = DURATION_NOTIFICATION,
  } = payload;

  const notificationsData = notificationsDataSelector(getState());

  const notification = {
    id: nanoid(),
    title,
    type,
    content,
    duration,
    autoClose,
  };

  dispatch({ type: NOTIFICATION_SET, payload: { data: notification, isImportant } });

  // remove the notification after DURATION_NOTIFICATION ms
  if (autoClose) {
    setTimeout(() => {
      dispatch(unsetNotification({ notificationId: notification.id }));
    }, duration);
  }

  // remove the oldest notification to only keep 5
  if (notificationsData.length === MAX_NOTIFICATION) {
    const firstNotificationId = fistNotificationIdSelector(getState());
    dispatch(unsetNotification({ notificationId: firstNotificationId }));
  }
};
