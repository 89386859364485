"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialState = void 0;
exports.initialState = {
    userRegisteredChallenges: {
        meta: null,
        data: null,
        error: false,
    },
    userGroupChallengeContribution: {
        data: null,
        isLoading: null,
        error: null,
    },
    registration: {
        success: null,
        error: null,
        isLoading: null,
    },
    userChallengeActivities: {
        data: null,
        meta: null,
        error: null,
        isLoading: null,
    },
    userChallengeActions: {
        data: null,
        meta: null,
        error: null,
        isLoading: null,
    },
    userChallengeActivitiesAround: {
        data: null,
        meta: null,
        error: null,
        isLoading: null,
    },
    userChallengeAttempts: {
        data: null,
        meta: null,
        error: null,
        isLoading: null,
    },
    hasUpdatedUserChallenge: null,
    userChallenge: { data: null },
    userGroupChallenge: {
        data: null,
        isLoading: null,
    },
};
function UserChallengesReducer(state = exports.initialState, action) {
    switch (action.type) {
        case 'OLY_LOAD_USER_REGISTERED_CHALLENGES_SUCCESS':
            return {
                ...state,
                userRegisteredChallenges: {
                    ...state.userRegisteredChallenges,
                    data: action.payload.data,
                    meta: action.payload.meta,
                },
            };
        case 'OLY_LOAD_USER_REGISTERED_CHALLENGES_ERROR':
            return {
                ...state,
                userRegisteredChallenges: {
                    ...state.userRegisteredChallenges,
                    error: action.payload.error,
                },
            };
        case 'OLY_RESET_USER_REGISTERED_CHALLENGES':
            return {
                ...state,
                userRegisteredChallenges: {
                    meta: null,
                    data: null,
                    error: null,
                },
            };
        case 'OLY_LOAD_USER_GROUP_CHALLENGE_STATS_REQUEST':
            return {
                ...state,
                userGroupChallengeContribution: {
                    ...state.userGroupChallengeContribution,
                    isLoading: true,
                },
            };
        case 'OLY_LOAD_USER_GROUP_CHALLENGE_STATS_SUCCESS':
            return {
                ...state,
                userGroupChallengeContribution: {
                    ...state.userGroupChallengeContribution,
                    data: action.payload.data,
                    isLoading: false,
                },
            };
        case 'OLY_LOAD_USER_GROUP_CHALLENGE_STATS_ERROR':
            return {
                ...state,
                userGroupChallengeContribution: {
                    ...state.userGroupChallengeContribution,
                    isLoading: false,
                    error: true,
                },
            };
        case 'OLY_RESET_USER_GROUP_CHALLENGE_STATS':
            return exports.initialState;
        case 'OLY_REGISTER_CHALLENGE_REQUEST':
            return {
                ...state,
                registration: {
                    ...exports.initialState.registration,
                    isLoading: true,
                },
            };
        case 'OLY_REGISTER_CHALLENGE_SUCCESS':
            return {
                ...state,
                registration: {
                    ...state.registration,
                    success: true,
                    isLoading: false,
                },
            };
        case 'OLY_REGISTER_CHALLENGE_ERROR':
            return {
                ...state,
                registration: {
                    ...state.registration,
                    error: action.payload.userMsg ? action.payload.userMsg : true,
                    isLoading: false,
                },
            };
        case 'OLY_LOAD_USER_CHALLENGE_SUCCESS':
            return {
                ...state,
                userChallenge: { data: action.payload },
            };
        case 'OLY_UPDATE_USER_CHALLENGE_SUCCESS':
            return {
                ...state,
                hasUpdatedUserChallenge: true,
            };
        case 'OLY_UPDATE_USER_CHALLENGE_ERROR':
            return {
                ...state,
                hasUpdatedUserChallenge: false,
            };
        case 'OLY_LOAD_USER_CHALLENGE_ACTIVITIES':
            return {
                ...state,
                userChallengeActivities: {
                    ...state.userChallengeActivities,
                    isLoading: true,
                },
            };
        case 'OLY_LOAD_USER_CHALLENGE_ACTIVITIES_SUCCESS':
            return {
                ...state,
                userChallengeActivities: {
                    ...state.userChallengeActivities,
                    ...action.payload.data,
                    isLoading: false,
                },
            };
        case 'OLY_LOAD_USER_CHALLENGE_ACTIVITIES_ERROR':
            return {
                ...state,
                userChallengeActivities: {
                    ...state.userChallengeActivities,
                    error: true,
                    isLoading: false,
                },
            };
        case 'OLY_LOAD_USER_CHALLENGE_ACTIONS':
            return {
                ...state,
                userChallengeActions: {
                    ...state.userChallengeActions,
                    isLoading: true,
                },
            };
        case 'OLY_LOAD_USER_CHALLENGE_ACTIONS_SUCCESS':
            return {
                ...state,
                userChallengeActions: {
                    ...state.userChallengeActions,
                    ...action.payload.data,
                    isLoading: false,
                },
            };
        case 'OLY_LOAD_USER_CHALLENGE_ACTIONS_ERROR':
            return {
                ...state,
                userChallengeActions: {
                    ...state.userChallengeActions,
                    error: true,
                    isLoading: false,
                },
            };
        case 'OLY_LOAD_USER_CHALLENGE_ACTIVITIES_AROUND':
            return {
                ...state,
                userChallengeActivitiesAround: {
                    ...state.userChallengeActivitiesAround,
                    isLoading: true,
                },
            };
        case 'OLY_LOAD_USER_CHALLENGE_ACTIVITIES_AROUND_SUCCESS':
            return {
                ...state,
                userChallengeActivitiesAround: {
                    ...state.userChallengeActivitiesAround,
                    ...action.payload.data,
                    isLoading: false,
                },
            };
        case 'OLY_LOAD_USER_CHALLENGE_ACTIVITIES_AROUND_ERROR':
            return {
                ...state,
                userChallengeActivitiesAround: {
                    ...state.userChallengeActivitiesAround,
                    error: true,
                    isLoading: false,
                },
            };
        case 'OLY_LOAD_USER_GROUP_CHALLENGE_SUCCESS':
            return {
                ...state,
                userGroupChallenge: {
                    data: action.payload,
                    isLoading: false,
                },
            };
        case 'OLY_LOAD_USER_GROUP_CHALLENGE_REQUEST':
            return {
                ...state,
                userGroupChallenge: {
                    ...state.userGroupChallenge,
                    isLoading: true,
                },
            };
        case 'OLY_SET_USER_GROUP_CHALLENGE':
            return {
                ...state,
                userGroupChallenge: {
                    ...state.userGroupChallenge,
                    data: action.payload,
                },
            };
        case 'OLY_RESET_USER_GROUP_CHALLENGE':
            return {
                ...state,
                userGroupChallenge: {
                    ...exports.initialState.userGroupChallenge,
                },
            };
        case 'OLY_LOAD_USER_CHALLENGE_ATTEMPTS':
            return {
                ...state,
                userChallengeAttempts: {
                    ...state.userChallengeAttempts,
                    isLoading: true,
                },
            };
        case 'OLY_LOAD_USER_CHALLENGE_ATTEMPTS_SUCCESS':
            return {
                ...state,
                userChallengeAttempts: {
                    ...state.userChallengeAttempts,
                    ...action.payload.data,
                    isLoading: false,
                },
            };
        case 'OLY_LOAD_USER_CHALLENGE_ATTEMPTS_ERROR':
            return {
                ...state,
                userChallengeAttempts: {
                    ...state.userChallengeAttempts,
                    error: true,
                    isLoading: false,
                },
            };
        case 'OLY_RESET_USER_CHALLENGE_ATTEMPTS':
            return {
                ...state,
                userChallengeAttempts: {
                    ...exports.initialState.userChallengeAttempts,
                },
            };
        case 'OLY_RESET_USER_CHALLENGE_ACTIVITIES':
            return {
                ...state,
                userChallengeActivitiesAround: {
                    ...exports.initialState.userChallengeActivitiesAround,
                },
                userChallengeActivities: {
                    ...exports.initialState.userChallengeActivities,
                },
            };
        case 'OLY_RESET_USER_CHALLENGES':
            return exports.initialState;
        default:
            return state;
    }
}
exports.default = UserChallengesReducer;
