"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reset = exports.setSuccess = exports.setError = exports.setLoading = void 0;
// Generic set state functions to be used in reducers
const setLoading = (state, subStateKey) => {
    return {
        ...state,
        [subStateKey]: {
            ...state[subStateKey],
            isLoading: true,
            success: null,
            error: null,
        },
    };
};
exports.setLoading = setLoading;
const setError = (state, subStateKey, error) => {
    return {
        ...state,
        [subStateKey]: {
            ...state[subStateKey],
            isLoading: false,
            success: false,
            error,
        },
    };
};
exports.setError = setError;
const setSuccess = (state, subStateKey, data) => {
    return {
        ...state,
        [subStateKey]: {
            ...state[subStateKey],
            ...(data || {}),
            isLoading: false,
            success: true,
            error: null,
        },
    };
};
exports.setSuccess = setSuccess;
const reset = (state, initState, subStateKey) => {
    return {
        ...state,
        [subStateKey]: initState[subStateKey],
    };
};
exports.reset = reset;
