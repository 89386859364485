"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteRedeemUserRewardState = void 0;
const redux_1 = require("redux");
exports.deleteRedeemUserRewardState = {
    userIdRedeemDeleted: null,
    isLoading: false,
    error: null,
};
const deleteRedeemUserRewardReducer = (state = exports.deleteRedeemUserRewardState, action) => {
    switch (action.type) {
        case 'OLY_ENGINE_DELETE_USER_REDEEM_REWARD':
            return { ...state, isLoading: true, error: null };
        case 'OLY_ENGINE_DELETE_USER_REDEEM_REWARD_SUCCESS':
            return {
                ...state,
                isLoading: false,
                userIdRedeemDeleted: action.payload,
            };
        case 'OLY_ENGINE_DELETE_USER_REDEEM_REWARD_ERROR':
            return { ...state, isLoading: false, error: action.payload.error };
        case 'OLY_ENGINE_DELETE_USER_REDEEM_REWARD_RESET':
            return exports.deleteRedeemUserRewardState;
        default:
            return state;
    }
};
exports.default = (0, redux_1.combineReducers)({
    deleteUserRedeemReward: deleteRedeemUserRewardReducer,
});
