import { createSelector } from 'reselect';
import get from 'lodash/get';

import { mergeArrays } from '../utils/mergeArrays';

const clientsSelector = state => get(state, 'clients');

// active client
export const clientsActiveSelector = createSelector(clientsSelector, clientsActive =>
  get(clientsActive, 'active'),
);

// inactive client
export const clientsInactiveSelector = createSelector(clientsSelector, clientsInactive =>
  get(clientsInactive, 'inactive'),
);

// current client
export const clientsCurrentSelector = createSelector(clientsSelector, clients =>
  get(clients, 'currentClient'),
);

// current client
export const clientsCurrentErrorSelector = createSelector(clientsSelector, clients =>
  get(clients, 'currentClientError'),
);

// current client all activities (activitiesType + sportheroesActivitiesType)
export const clientsCurrentAllActivitiesTypeSelector = createSelector(
  clientsCurrentSelector,
  currentClient => {
    const { activitiesType = [], sportheroesActivitiesType = [] } = currentClient || {};
    return mergeArrays(activitiesType, sportheroesActivitiesType);
  },
);

// current client activitiesType for manual activity creation
export const clientsCurrentManualActivitiesTypeSelector = createSelector(
  clientsCurrentSelector,
  currentClient => get(currentClient, 'activitiesType'),
);

// current client profileActivitiesType
export const clientsProfileActivitiesTypeSelector = createSelector(
  clientsCurrentSelector,
  currentClient => get(currentClient, 'profileActivitiesType'),
);

// current client slug
export const clientsCurrentSlugSelector = createSelector(clientsCurrentSelector, currentClient =>
  get(currentClient, 'slug'),
);

// current client rewardConfig
export const clientsCurrentRewardConfigSelector = createSelector(
  clientsCurrentSelector,
  currentClient => get(currentClient, 'features.rewardConfig'),
);

// list clients
export const clientsListSelector = createSelector(clientsSelector, clientsList =>
  get(clientsList, 'list'),
);

// list clients data
export const clientsListDataSelector = createSelector(clientsListSelector, clients =>
  get(clients, 'data'),
);

// list clients isLoading
export const clientsListIsLoadingSelector = createSelector(clientsListSelector, clients =>
  get(clients, 'isLoading'),
);

// list clients error
export const clientsListErrorSelector = createSelector(clientsListSelector, clients =>
  get(clients, 'error'),
);

// get current client ID
export const clientsCurrentIdSelector = createSelector(clientsCurrentSelector, clientsCurrent =>
  get(clientsCurrent, '_id'),
);

// get current client Name
export const clientsCurrentNameSelector = createSelector(clientsCurrentSelector, clientsCurrent =>
  get(clientsCurrent, 'name'),
);

// get current client supportedCurrencies
export const clientSupportedCurrenciesSelector = createSelector(
  clientsCurrentSelector,
  clientsCurrent => get(clientsCurrent, 'supportedCurrencies'),
);

// get current client supportedApps
export const clientSupportedAppsSelector = createSelector(clientsCurrentSelector, clientsCurrent =>
  get(clientsCurrent, 'supportedApps.apps'),
);

// get current client defaultMeasurementSystem
export const clientDefaultMeasurementSystemSelector = createSelector(
  clientsCurrentSelector,
  clientsCurrent =>
    get(clientsCurrent, 'supportedMeasurementSystems.defaultMeasurementSystem', 'metric'),
);

// list clients available languages
export const clientsCurrentSupportedLanguagesSelector = createSelector(
  clientsCurrentSelector,
  clientsCurrent =>
    get(clientsCurrent, 'supportedLanguages')
      ? get(clientsCurrent, 'supportedLanguages.available') || [
          get(clientsCurrent, 'supportedLanguages.defaultLanguage'),
        ]
      : [],
);

export const defaultLanguageSelector = createSelector(clientsCurrentSelector, clientsCurrent =>
  get(clientsCurrent, 'supportedLanguages.defaultLanguage', 'fr'),
);
