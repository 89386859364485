"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const deleteUserRedeemReward = (payload) => ({
    type: 'OLY_ENGINE_DELETE_USER_REDEEM_REWARD',
    payload,
});
const deleteUserRedeemRewardSuccess = (userId) => ({
    type: 'OLY_ENGINE_DELETE_USER_REDEEM_REWARD_SUCCESS',
    payload: userId,
});
const deleteUserRedeemRewardError = (error) => ({
    type: 'OLY_ENGINE_DELETE_USER_REDEEM_REWARD_ERROR',
    payload: { error },
});
const deleteUserRedeemRewardReset = () => ({
    type: 'OLY_ENGINE_DELETE_USER_REDEEM_REWARD_RESET',
});
exports.default = {
    deleteUserRedeemReward,
    deleteUserRedeemRewardSuccess,
    deleteUserRedeemRewardError,
    deleteUserRedeemRewardReset,
};
