"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteUserRedeemRewardIsLoadingSelector = exports.deleteUserRedeemRewardErrorSelector = exports.deletedUserIdRedeemRewardSelector = exports.deleteUserRedeemRewardSelector = exports.userRewardsStateSelector = void 0;
const reselect_1 = require("reselect");
const userStateSelector = (state) => state.user;
exports.userRewardsStateSelector = (0, reselect_1.createSelector)(userStateSelector, (user) => user.rewards);
exports.deleteUserRedeemRewardSelector = (0, reselect_1.createSelector)(exports.userRewardsStateSelector, (rewards) => rewards.deleteUserRedeemReward);
exports.deletedUserIdRedeemRewardSelector = (0, reselect_1.createSelector)(exports.deleteUserRedeemRewardSelector, (deleteUserRedeemReward) => deleteUserRedeemReward.userIdRedeemDeleted);
exports.deleteUserRedeemRewardErrorSelector = (0, reselect_1.createSelector)(exports.deleteUserRedeemRewardSelector, (deleteUserRedeemReward) => deleteUserRedeemReward.error);
exports.deleteUserRedeemRewardIsLoadingSelector = (0, reselect_1.createSelector)(exports.deleteUserRedeemRewardSelector, (deleteUserRedeemReward) => deleteUserRedeemReward.isLoading);
exports.default = {
    deletedUserIdRedeemRewardSelector: exports.deletedUserIdRedeemRewardSelector,
    deleteUserRedeemRewardErrorSelector: exports.deleteUserRedeemRewardErrorSelector,
    deleteUserRedeemRewardIsLoadingSelector: exports.deleteUserRedeemRewardIsLoadingSelector,
};
