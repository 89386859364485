"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const uploadProfilePicture = (payload) => ({
    type: 'OLY_UPLOAD_PROFILE_PICTURE',
    payload,
});
const uploadProfilePictureSuccess = () => ({
    type: 'OLY_UPLOAD_PROFILE_PICTURE_SUCCESS',
});
const uploadProfilePictureError = (error) => ({
    type: 'OLY_UPLOAD_PROFILE_PICTURE_ERROR',
    payload: { error },
});
const uploadProfilePictureReset = () => ({
    type: 'OLY_UPLOAD_PROFILE_PICTURE_RESET',
});
exports.default = {
    uploadProfilePicture,
    uploadProfilePictureSuccess,
    uploadProfilePictureError,
    uploadProfilePictureReset,
};
