"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userClientsErrorSelector = exports.getCurrentUserClientSettingsSelector = exports.getCurrentUserClientSelector = exports.currentUserClientSelector = exports.currentUserClientIdSelector = exports.currentAdminUserClientsSelector = exports.userClientsSelector = exports.userClientStateSelector = void 0;
const reselect_1 = require("reselect");
const userStateSelector = (state) => state.user;
exports.userClientStateSelector = (0, reselect_1.createSelector)(userStateSelector, (user) => user.clients);
exports.userClientsSelector = (0, reselect_1.createSelector)(exports.userClientStateSelector, (clients) => clients.userClients);
exports.currentAdminUserClientsSelector = (0, reselect_1.createSelector)(exports.userClientStateSelector, (clients) => clients.currentAdminUserClients);
exports.currentUserClientIdSelector = (0, reselect_1.createSelector)(exports.userClientStateSelector, clients => clients.currentUserClientId);
exports.currentUserClientSelector = (0, reselect_1.createSelector)(exports.userClientStateSelector, clients => { var _a; return (_a = clients.userClients) === null || _a === void 0 ? void 0 : _a.find(userClient => { var _a; return ((_a = userClient.client) === null || _a === void 0 ? void 0 : _a._id) === clients.currentUserClientId; }); });
exports.getCurrentUserClientSelector = (0, reselect_1.createSelector)(exports.userClientStateSelector, clients => clients.currentUserClient);
exports.getCurrentUserClientSettingsSelector = (0, reselect_1.createSelector)(exports.getCurrentUserClientSelector, userClient => userClient === null || userClient === void 0 ? void 0 : userClient.settings);
exports.userClientsErrorSelector = (0, reselect_1.createSelector)(exports.userClientStateSelector, (clients) => clients.error);
exports.default = {
    userClientsSelector: exports.userClientsSelector,
    currentAdminUserClientsSelector: exports.currentAdminUserClientsSelector,
    currentUserClientIdSelector: exports.currentUserClientIdSelector,
    currentUserClientSelector: exports.currentUserClientSelector,
    userClientsErrorSelector: exports.userClientsErrorSelector,
    getCurrentUserClientSelector: exports.getCurrentUserClientSelector,
    getCurrentUserClientSettingsSelector: exports.getCurrentUserClientSettingsSelector,
};
