"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userInfoState = exports.deleteUserState = exports.deleteUserInfoClientState = exports.deleteUserInfoGroupState = exports.editUserInfoState = exports.editUserClientState = exports.loadUserInfoState = void 0;
const redux_1 = require("redux");
exports.loadUserInfoState = {
    userInfo: null,
    isLoading: false,
    error: null,
};
const loadUserInfoReducer = (state = exports.loadUserInfoState, action) => {
    switch (action.type) {
        case 'OLY_LOAD_USER_INFO':
            return {
                ...exports.loadUserInfoState,
                isLoading: true,
            };
        case 'OLY_LOAD_USER_INFO_SUCCESS':
            return {
                ...exports.loadUserInfoState,
                userInfo: action.payload.userInfo,
            };
        case 'OLY_LOAD_USER_INFO_ERROR':
            return {
                ...exports.loadUserInfoState,
                error: action.payload.error,
            };
        case 'OLY_LOAD_USER_INFO_RESET':
            return exports.loadUserInfoState;
        default:
            return state;
    }
};
exports.editUserClientState = {
    success: null,
    isLoading: false,
    error: null,
};
const editUserClientReducer = (state = exports.editUserClientState, action) => {
    switch (action.type) {
        case 'OLY_EDIT_USER_CLIENT':
            return {
                ...exports.editUserClientState,
                isLoading: true,
            };
        case 'OLY_EDIT_USER_CLIENT_SUCCESS':
            return {
                ...exports.editUserClientState,
                success: true,
            };
        case 'OLY_EDIT_USER_CLIENT_ERROR':
            return {
                ...exports.editUserClientState,
                error: action.payload.error,
            };
        case 'OLY_EDIT_USER_CLIENT_RESET':
            return exports.editUserClientState;
        default:
            return state;
    }
};
exports.editUserInfoState = {
    editedUserId: null,
    isLoading: false,
    error: null,
};
const editUserInfoReducer = (state = exports.editUserInfoState, action) => {
    switch (action.type) {
        case 'OLY_EDIT_USER_INFO':
            return {
                ...exports.editUserInfoState,
                isLoading: true,
            };
        case 'OLY_EDIT_USER_INFO_SUCCESS':
            return {
                ...exports.editUserInfoState,
                editedUserId: action.payload.editedUserId,
            };
        case 'OLY_EDIT_USER_INFO_ERROR':
            return {
                ...exports.editUserInfoState,
                error: action.payload.error,
            };
        case 'OLY_EDIT_USER_INFO_RESET':
            return exports.editUserInfoState;
        default:
            return state;
    }
};
exports.deleteUserInfoGroupState = {
    deletedUserGroupId: null,
    isLoading: false,
    error: null,
};
const deleteUserInfoGroupReducer = (state = exports.deleteUserInfoGroupState, action) => {
    switch (action.type) {
        case 'OLY_DELETE_USER_INFO_GROUP':
            return {
                ...exports.deleteUserInfoGroupState,
                isLoading: true,
            };
        case 'OLY_DELETE_USER_INFO_GROUP_SUCCESS':
            return {
                ...exports.deleteUserInfoGroupState,
                deletedUserGroupId: action.payload.deletedUserGroupId,
            };
        case 'OLY_DELETE_USER_INFO_GROUP_ERROR':
            return {
                ...exports.deleteUserInfoGroupState,
                error: action.payload.error,
            };
        case 'OLY_DELETE_USER_INFO_GROUP_RESET':
            return exports.deleteUserInfoGroupState;
        default:
            return state;
    }
};
exports.deleteUserInfoClientState = {
    deletedUserClientId: null,
    isLoading: false,
    error: null,
};
const deleteUserInfoClientReducer = (state = exports.deleteUserInfoClientState, action) => {
    switch (action.type) {
        case 'OLY_DELETE_USER_INFO_CLIENT':
            return {
                ...exports.deleteUserInfoClientState,
                isLoading: true,
            };
        case 'OLY_DELETE_USER_INFO_CLIENT_SUCCESS':
            return {
                ...exports.deleteUserInfoClientState,
                deletedUserClientId: action.payload.deletedUserClientId,
            };
        case 'OLY_DELETE_USER_INFO_CLIENT_ERROR':
            return {
                ...exports.deleteUserInfoClientState,
                error: action.payload.error,
            };
        case 'OLY_DELETE_USER_INFO_CLIENT_RESET':
            return exports.deleteUserInfoClientState;
        default:
            return state;
    }
};
exports.deleteUserState = {
    deletedUserId: null,
    isLoading: false,
    error: null,
};
const deleteUserReducer = (state = exports.deleteUserState, action) => {
    switch (action.type) {
        case 'OLY_DELETE_USER':
            return {
                ...exports.deleteUserState,
                isLoading: true,
            };
        case 'OLY_DELETE_USER_SUCCESS':
            return {
                ...exports.deleteUserState,
                deletedUserId: action.payload.deletedUserId,
            };
        case 'OLY_DELETE_USER_ERROR':
            return {
                ...exports.deleteUserState,
                error: action.payload.error,
            };
        case 'OLY_DELETE_USER_RESET':
            return exports.deleteUserState;
        default:
            return state;
    }
};
exports.userInfoState = {
    loadUserInfo: exports.loadUserInfoState,
    editUserInfo: exports.editUserInfoState,
    editUserClient: exports.editUserClientState,
    deleteUserInfoGroup: exports.deleteUserInfoGroupState,
    deleteUserInfoClient: exports.deleteUserInfoClientState,
    deleteUser: exports.deleteUserState,
};
exports.default = (0, redux_1.combineReducers)({
    loadUserInfo: loadUserInfoReducer,
    editUserInfo: editUserInfoReducer,
    editUserClient: editUserClientReducer,
    deleteUserInfoGroup: deleteUserInfoGroupReducer,
    deleteUserInfoClient: deleteUserInfoClientReducer,
    deleteUser: deleteUserReducer,
});
