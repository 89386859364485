"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialState = void 0;
exports.initialState = {
    userClients: null,
    currentAdminUserClients: null,
    currentUserClientId: null,
    error: false,
    currentUserClient: null,
};
const reducer = (state = exports.initialState, action) => {
    var _a;
    switch (action.type) {
        case 'OLY_ENGINE_STORE_LOAD_USER_CLIENTS':
        case 'OLY_LOAD_ONE_USER_CLIENT':
        case 'OLY_UPDATE_USER_CLIENT_SETTINGS':
            return {
                ...state,
                error: exports.initialState.error,
            };
        case 'OLY_ENGINE_STORE_LOAD_USER_CLIENTS_SUCCESS':
            return {
                ...state,
                userClients: action.payload.userClients,
            };
        case 'OLY_ENGINE_STORE_LOAD_USER_CLIENTS_ERROR':
        case 'OLY_LOAD_ONE_USER_CLIENT_ERROR':
        case 'OLY_UPDATE_USER_CLIENT_SETTINGS_ERROR':
            return {
                ...state,
                error: action.payload.error,
            };
        case 'OLY_ENGINE_STORE_SET_CURRENT_USER_CLIENT_ID':
            return {
                ...state,
                currentUserClientId: action.payload.clientId,
            };
        case 'OLY_ENGINE_STORE_RESET_USER_CLIENTS':
            return exports.initialState;
        case 'OLY_LOAD_ONE_USER_CLIENT_SUCCESS':
            return {
                ...state,
                currentUserClient: action.payload.userClient,
            };
        case 'OLY_UPDATE_USER_CLIENT_SETTINGS_SUCCESS':
            return {
                ...state,
                currentUserClient: {
                    ...state.currentUserClient,
                    settings: {
                        ...(_a = state.currentUserClient) === null || _a === void 0 ? void 0 : _a.settings,
                        ...action.payload.settings,
                    },
                },
            };
        default:
            return state;
    }
};
exports.default = reducer;
