"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userActivitiesState = exports.deleteUserActivityState = exports.editUserActivityState = exports.createUserActivityState = exports.replayUserActivityState = exports.loadUserActivitiesState = void 0;
const redux_1 = require("redux");
// LOAD USER ACTIVITIES
exports.loadUserActivitiesState = {
    data: null,
    metadata: null,
    isLoading: false,
    error: null,
};
const loadUserActivitiesReducer = (state = exports.loadUserActivitiesState, action) => {
    switch (action.type) {
        case 'OLY_ENGINE_STORE_LOAD_USER_ACTIVITIES':
            return { ...state, isLoading: true, error: null };
        case 'OLY_ENGINE_STORE_LOAD_USER_ACTIVITIES_SUCCESS':
            return {
                ...state,
                isLoading: false,
                data: action.payload.metadata.skip > 0 && state.data
                    ? [...state.data, ...action.payload.data]
                    : action.payload.data,
                metadata: action.payload.metadata,
            };
        case 'OLY_ENGINE_STORE_LOAD_USER_ACTIVITIES_ERROR':
            return { ...state, isLoading: false, error: action.payload.error };
        case 'OLY_ENGINE_STORE_RESET_USER_ACTIVITIES':
            return exports.loadUserActivitiesState;
        default:
            return state;
    }
};
// REPLAY USER ACTIVITY
exports.replayUserActivityState = {
    replayedActivityId: null,
    isLoading: false,
    error: null,
};
const replayUserActivityReducer = (state = exports.replayUserActivityState, action) => {
    switch (action.type) {
        case 'OLY_ENGINE_STORE_REPLAY_USER_ACTIVITY':
            return { replayedActivityId: null, isLoading: true, error: null };
        case 'OLY_ENGINE_STORE_REPLAY_USER_ACTIVITY_SUCCESS':
            return {
                replayedActivityId: action.payload.activityId,
                isLoading: false,
                error: null,
            };
        case 'OLY_ENGINE_STORE_REPLAY_USER_ACTIVITY_ERROR':
            return { ...state, isLoading: false, error: action.payload.error };
        case 'OLY_ENGINE_STORE_REPLAY_USER_ACTIVITY_RESET':
            return exports.replayUserActivityState;
        default:
            return state;
    }
};
// CREATE USER ACTIVITY
exports.createUserActivityState = {
    createdActivity: null,
    isLoading: false,
    error: null,
};
const createUserActivityReducer = (state = exports.createUserActivityState, action) => {
    switch (action.type) {
        case 'OLY_ENGINE_STORE_CREATE_USER_ACTIVITY':
            return { createdActivity: null, isLoading: true, error: null };
        case 'OLY_ENGINE_STORE_CREATE_USER_ACTIVITY_SUCCESS':
            return {
                createdActivity: true,
                isLoading: false,
                error: null,
            };
        case 'OLY_ENGINE_STORE_CREATE_USER_ACTIVITY_ERROR':
            return { ...state, isLoading: false, error: action.payload.error };
        case 'OLY_ENGINE_STORE_CREATE_USER_ACTIVITY_RESET':
            return exports.createUserActivityState;
        default:
            return state;
    }
};
// EDIT USER ACTIVITY
exports.editUserActivityState = {
    editedActivityId: null,
    isLoading: false,
    error: null,
};
const editUserActivityReducer = (state = exports.editUserActivityState, action) => {
    switch (action.type) {
        case 'OLY_ENGINE_STORE_EDIT_USER_ACTIVITY':
            return { editedActivityId: null, isLoading: true, error: null };
        case 'OLY_ENGINE_STORE_EDIT_USER_ACTIVITY_SUCCESS':
            return {
                editedActivityId: action.payload.activityId,
                isLoading: false,
                error: null,
            };
        case 'OLY_ENGINE_STORE_EDIT_USER_ACTIVITY_ERROR':
            return { ...state, isLoading: false, error: action.payload.error };
        case 'OLY_ENGINE_STORE_EDIT_USER_ACTIVITY_RESET':
            return exports.editUserActivityState;
        default:
            return state;
    }
};
// DELETE USER ACTIVITY
exports.deleteUserActivityState = {
    deletedActivityId: null,
    isLoading: false,
    error: null,
};
const deleteUserActivityReducer = (state = exports.deleteUserActivityState, action) => {
    switch (action.type) {
        case 'OLY_ENGINE_STORE_DELETE_USER_ACTIVITY':
            return { deletedActivityId: null, isLoading: true, error: null };
        case 'OLY_ENGINE_STORE_DELETE_USER_ACTIVITY_SUCCESS':
            return {
                deletedActivityId: action.payload.activityId,
                isLoading: false,
                error: null,
            };
        case 'OLY_ENGINE_STORE_DELETE_USER_ACTIVITY_ERROR':
            return { ...state, isLoading: false, error: action.payload.error };
        case 'OLY_ENGINE_STORE_DELETE_USER_ACTIVITY_RESET':
            return exports.deleteUserActivityState;
        default:
            return state;
    }
};
exports.userActivitiesState = {
    loadUserActivities: exports.loadUserActivitiesState,
    replayUserActivity: exports.replayUserActivityState,
    createUserActivity: exports.createUserActivityState,
    editUserActivity: exports.editUserActivityState,
    deleteUserActivity: exports.deleteUserActivityState,
};
exports.default = (0, redux_1.combineReducers)({
    loadUserActivities: loadUserActivitiesReducer,
    replayUserActivity: replayUserActivityReducer,
    createUserActivity: createUserActivityReducer,
    editUserActivity: editUserActivityReducer,
    deleteUserActivity: deleteUserActivityReducer,
});
