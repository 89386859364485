"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("./constants");
const INITIAL_STATE = {
    currentSubSectionId: null,
    sectionsCollapsed: [],
    collapsedSectionsLangs: null,
};
const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case constants_1.SET_CURRENT_SUBSECTION_ID:
            return { ...state, currentSubSectionId: action.payload };
        case constants_1.SET_SECTIONS_COLLAPSED:
            return { ...state, sectionsCollapsed: action.payload };
        case constants_1.SET_COLLAPSE_SECTIONS_LANGS:
            return { ...state, collapsedSectionsLangs: action.payload };
        default:
            return state;
    }
};
exports.default = reducer;
