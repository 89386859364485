"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userFeedErrorSelector = exports.userFeedhasNextSelector = exports.userFeedNextSelector = exports.userFeedDataSelector = exports.userFeedSelector = exports.userFeedStateSelector = void 0;
const reselect_1 = require("reselect");
const userStateSelector = (state) => state.user;
exports.userFeedStateSelector = (0, reselect_1.createSelector)(userStateSelector, (user) => user.feed);
exports.userFeedSelector = (0, reselect_1.createSelector)(exports.userFeedStateSelector, (feed) => feed.userFeed);
exports.userFeedDataSelector = (0, reselect_1.createSelector)(exports.userFeedSelector, (userFeed) => (userFeed ? userFeed.data : null));
const userFeedMetaSelector = (0, reselect_1.createSelector)(exports.userFeedSelector, (userFeed) => (userFeed ? userFeed.meta : null));
exports.userFeedNextSelector = (0, reselect_1.createSelector)(userFeedMetaSelector, (userFeedMeta) => (userFeedMeta ? userFeedMeta.next : null));
exports.userFeedhasNextSelector = (0, reselect_1.createSelector)(userFeedMetaSelector, (userFeedMeta) => (userFeedMeta ? userFeedMeta.hasNext : null));
exports.userFeedErrorSelector = (0, reselect_1.createSelector)(exports.userFeedStateSelector, (feed) => feed.error);
exports.default = {
    userFeedDataSelector: exports.userFeedDataSelector,
    userFeedMetaSelector,
    userFeedNextSelector: exports.userFeedNextSelector,
    userFeedhasNextSelector: exports.userFeedhasNextSelector,
    userFeedErrorSelector: exports.userFeedErrorSelector,
};
