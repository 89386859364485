"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userAppsReset = exports.deleteUserAppError = exports.deleteUserAppSuccess = exports.deleteUserApp = exports.pullUserAppsError = exports.pullUserAppsSuccess = exports.pullUserApps = exports.loadUserAppsError = exports.loadUserAppsSuccess = exports.loadUserApps = void 0;
// LOAD USER APPS
const loadUserApps = (payload) => ({
    type: 'OLY_LOAD_USER_APPS',
    payload,
});
exports.loadUserApps = loadUserApps;
const loadUserAppsSuccess = (loadUserApps) => ({
    type: 'OLY_LOAD_USER_APPS_SUCCESS',
    payload: { loadUserApps },
});
exports.loadUserAppsSuccess = loadUserAppsSuccess;
const loadUserAppsError = (error) => ({
    type: 'OLY_LOAD_USER_APPS_ERROR',
    payload: { error },
});
exports.loadUserAppsError = loadUserAppsError;
// PULL USER APPS
const pullUserApps = (payload) => ({
    type: 'OLY_PULL_USER_APPS',
    payload,
});
exports.pullUserApps = pullUserApps;
const pullUserAppsSuccess = () => ({
    type: 'OLY_PULL_USER_APPS_SUCCESS',
});
exports.pullUserAppsSuccess = pullUserAppsSuccess;
const pullUserAppsError = (error) => ({
    type: 'OLY_PULL_USER_APPS_ERROR',
    payload: { error },
});
exports.pullUserAppsError = pullUserAppsError;
// DELETE USER APP
const deleteUserApp = (payload) => ({
    type: 'OLY_DELETE_USER_APP',
    payload,
});
exports.deleteUserApp = deleteUserApp;
const deleteUserAppSuccess = (deletedAppName) => ({
    type: 'OLY_DELETE_USER_APP_SUCCESS',
    payload: { deletedAppName },
});
exports.deleteUserAppSuccess = deleteUserAppSuccess;
const deleteUserAppError = (error) => ({
    type: 'OLY_DELETE_USER_APP_ERROR',
    payload: { error },
});
exports.deleteUserAppError = deleteUserAppError;
// USER APP RESET
const userAppsReset = () => ({
    type: 'OLY_USER_APP_RESET',
});
exports.userAppsReset = userAppsReset;
exports.default = {
    loadUserApps: exports.loadUserApps,
    loadUserAppsSuccess: exports.loadUserAppsSuccess,
    loadUserAppsError: exports.loadUserAppsError,
    pullUserApps: exports.pullUserApps,
    pullUserAppsSuccess: exports.pullUserAppsSuccess,
    pullUserAppsError: exports.pullUserAppsError,
    deleteUserApp: exports.deleteUserApp,
    deleteUserAppSuccess: exports.deleteUserAppSuccess,
    deleteUserAppError: exports.deleteUserAppError,
    userAppsReset: exports.userAppsReset,
};
