"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialState = void 0;
exports.initialState = {
    loadUserApps: {
        data: null,
        isLoading: false,
        error: null,
    },
    pullUserApps: {
        isLoading: false,
        success: null,
        error: null,
    },
    deleteUserApp: {
        deletedAppName: null,
        isLoading: false,
        error: null,
    },
};
const appsReducer = (state = exports.initialState, action) => {
    switch (action.type) {
        case 'OLY_LOAD_USER_APPS':
            return {
                ...state,
                loadUserApps: {
                    ...exports.initialState.loadUserApps,
                    isLoading: true,
                },
            };
        case 'OLY_LOAD_USER_APPS_SUCCESS':
            return {
                ...state,
                loadUserApps: {
                    ...exports.initialState.loadUserApps,
                    data: action.payload.loadUserApps,
                },
            };
        case 'OLY_LOAD_USER_APPS_ERROR':
            return {
                ...state,
                loadUserApps: {
                    ...exports.initialState.loadUserApps,
                    error: action.payload.error,
                },
            };
        case 'OLY_PULL_USER_APPS':
            return {
                ...state,
                pullUserApps: {
                    ...exports.initialState.pullUserApps,
                    isLoading: true,
                },
            };
        case 'OLY_PULL_USER_APPS_SUCCESS':
            return {
                ...state,
                pullUserApps: {
                    ...exports.initialState.pullUserApps,
                    success: true,
                },
            };
        case 'OLY_PULL_USER_APPS_ERROR':
            return {
                ...state,
                pullUserApps: {
                    ...exports.initialState.pullUserApps,
                    error: action.payload.error,
                },
            };
        case 'OLY_DELETE_USER_APP':
            return {
                ...state,
                deleteUserApp: {
                    ...exports.initialState.deleteUserApp,
                    isLoading: true,
                },
            };
        case 'OLY_DELETE_USER_APP_SUCCESS':
            return {
                ...state,
                deleteUserApp: {
                    ...exports.initialState.deleteUserApp,
                    deletedAppName: action.payload.deletedAppName,
                },
            };
        case 'OLY_DELETE_USER_APP_ERROR':
            return {
                ...state,
                deleteUserApp: {
                    ...exports.initialState.deleteUserApp,
                    error: action.payload.error,
                },
            };
        case 'OLY_USER_APP_RESET':
            return exports.initialState;
        default:
            return state;
    }
};
exports.default = appsReducer;
