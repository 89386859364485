"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uploadProfilePictureIsLoadingSelector = exports.uploadProfilePictureErrorSelector = exports.profilePictureHasBeenUploadedSelector = exports.uploadProfilePictureSelector = exports.userCoreStateSelector = void 0;
const reselect_1 = require("reselect");
const userStateSelector = (state) => state.user;
exports.userCoreStateSelector = (0, reselect_1.createSelector)(userStateSelector, (user) => user.core);
exports.uploadProfilePictureSelector = (0, reselect_1.createSelector)(exports.userCoreStateSelector, (core) => core.uploadProfilePicture);
exports.profilePictureHasBeenUploadedSelector = (0, reselect_1.createSelector)(exports.uploadProfilePictureSelector, (uploadProfilePicture) => uploadProfilePicture.hasBeenUploaded);
exports.uploadProfilePictureErrorSelector = (0, reselect_1.createSelector)(exports.uploadProfilePictureSelector, (uploadProfilePicture) => uploadProfilePicture.error);
exports.uploadProfilePictureIsLoadingSelector = (0, reselect_1.createSelector)(exports.uploadProfilePictureSelector, (uploadProfilePicture) => uploadProfilePicture.isLoading);
exports.default = {
    profilePictureHasBeenUploadedSelector: exports.profilePictureHasBeenUploadedSelector,
    uploadProfilePictureErrorSelector: exports.uploadProfilePictureErrorSelector,
    uploadProfilePictureIsLoadingSelector: exports.uploadProfilePictureIsLoadingSelector,
};
