"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteUserActivityReset = exports.deleteUserActivityError = exports.deleteUserActivitySuccess = exports.deleteUserActivity = exports.editUserActivityReset = exports.editUserActivityError = exports.editUserActivitySuccess = exports.editUserActivity = exports.createUserActivityReset = exports.createUserActivityError = exports.createUserActivitySuccess = exports.createUserActivity = exports.replayUserActivitiesReset = exports.replayUserActivityError = exports.replayUserActivitySuccess = exports.replayUserActivity = exports.resetUserActivities = exports.loadUserActivitiesError = exports.loadUserActivitiesSuccess = exports.loadUserActivities = void 0;
// LOAD USER ACTIVITIES
const loadUserActivities = (payload) => ({
    type: 'OLY_ENGINE_STORE_LOAD_USER_ACTIVITIES',
    payload,
});
exports.loadUserActivities = loadUserActivities;
const loadUserActivitiesSuccess = (payload) => ({
    type: 'OLY_ENGINE_STORE_LOAD_USER_ACTIVITIES_SUCCESS',
    payload,
});
exports.loadUserActivitiesSuccess = loadUserActivitiesSuccess;
const loadUserActivitiesError = (error) => ({
    type: 'OLY_ENGINE_STORE_LOAD_USER_ACTIVITIES_ERROR',
    payload: { error },
});
exports.loadUserActivitiesError = loadUserActivitiesError;
const resetUserActivities = () => ({
    type: 'OLY_ENGINE_STORE_RESET_USER_ACTIVITIES',
});
exports.resetUserActivities = resetUserActivities;
// REPLAY USER ACTIVITY
const replayUserActivity = (payload) => ({
    type: 'OLY_ENGINE_STORE_REPLAY_USER_ACTIVITY',
    payload,
});
exports.replayUserActivity = replayUserActivity;
const replayUserActivitySuccess = (payload) => ({
    type: 'OLY_ENGINE_STORE_REPLAY_USER_ACTIVITY_SUCCESS',
    payload,
});
exports.replayUserActivitySuccess = replayUserActivitySuccess;
const replayUserActivityError = (error) => ({
    type: 'OLY_ENGINE_STORE_REPLAY_USER_ACTIVITY_ERROR',
    payload: { error },
});
exports.replayUserActivityError = replayUserActivityError;
const replayUserActivitiesReset = () => ({
    type: 'OLY_ENGINE_STORE_REPLAY_USER_ACTIVITY_RESET',
});
exports.replayUserActivitiesReset = replayUserActivitiesReset;
// CREATE USER ACTIVITY
const createUserActivity = (payload) => ({
    type: 'OLY_ENGINE_STORE_CREATE_USER_ACTIVITY',
    payload,
});
exports.createUserActivity = createUserActivity;
const createUserActivitySuccess = () => ({
    type: 'OLY_ENGINE_STORE_CREATE_USER_ACTIVITY_SUCCESS',
});
exports.createUserActivitySuccess = createUserActivitySuccess;
const createUserActivityError = (error) => ({
    type: 'OLY_ENGINE_STORE_CREATE_USER_ACTIVITY_ERROR',
    payload: { error },
});
exports.createUserActivityError = createUserActivityError;
const createUserActivityReset = () => ({
    type: 'OLY_ENGINE_STORE_CREATE_USER_ACTIVITY_RESET',
});
exports.createUserActivityReset = createUserActivityReset;
// EDIT USER ACTIVITY
const editUserActivity = (payload) => ({
    type: 'OLY_ENGINE_STORE_EDIT_USER_ACTIVITY',
    payload,
});
exports.editUserActivity = editUserActivity;
const editUserActivitySuccess = (payload) => ({
    type: 'OLY_ENGINE_STORE_EDIT_USER_ACTIVITY_SUCCESS',
    payload,
});
exports.editUserActivitySuccess = editUserActivitySuccess;
const editUserActivityError = (error) => ({
    type: 'OLY_ENGINE_STORE_EDIT_USER_ACTIVITY_ERROR',
    payload: { error },
});
exports.editUserActivityError = editUserActivityError;
const editUserActivityReset = () => ({
    type: 'OLY_ENGINE_STORE_EDIT_USER_ACTIVITY_RESET',
});
exports.editUserActivityReset = editUserActivityReset;
// DELETE USER ACTIVITY
const deleteUserActivity = (payload) => ({
    type: 'OLY_ENGINE_STORE_DELETE_USER_ACTIVITY',
    payload,
});
exports.deleteUserActivity = deleteUserActivity;
const deleteUserActivitySuccess = (payload) => ({
    type: 'OLY_ENGINE_STORE_DELETE_USER_ACTIVITY_SUCCESS',
    payload,
});
exports.deleteUserActivitySuccess = deleteUserActivitySuccess;
const deleteUserActivityError = (error) => ({
    type: 'OLY_ENGINE_STORE_DELETE_USER_ACTIVITY_ERROR',
    payload: { error },
});
exports.deleteUserActivityError = deleteUserActivityError;
const deleteUserActivityReset = () => ({
    type: 'OLY_ENGINE_STORE_DELETE_USER_ACTIVITY_RESET',
});
exports.deleteUserActivityReset = deleteUserActivityReset;
exports.default = {
    loadUserActivities: exports.loadUserActivities,
    loadUserActivitiesSuccess: exports.loadUserActivitiesSuccess,
    loadUserActivitiesError: exports.loadUserActivitiesError,
    resetUserActivities: exports.resetUserActivities,
    replayUserActivity: exports.replayUserActivity,
    replayUserActivitySuccess: exports.replayUserActivitySuccess,
    replayUserActivityError: exports.replayUserActivityError,
    replayUserActivitiesReset: exports.replayUserActivitiesReset,
    createUserActivity: exports.createUserActivity,
    createUserActivitySuccess: exports.createUserActivitySuccess,
    createUserActivityError: exports.createUserActivityError,
    createUserActivityReset: exports.createUserActivityReset,
    editUserActivity: exports.editUserActivity,
    editUserActivitySuccess: exports.editUserActivitySuccess,
    editUserActivityError: exports.editUserActivityError,
    editUserActivityReset: exports.editUserActivityReset,
    deleteUserActivity: exports.deleteUserActivity,
    deleteUserActivitySuccess: exports.deleteUserActivitySuccess,
    deleteUserActivityError: exports.deleteUserActivityError,
    deleteUserActivityReset: exports.deleteUserActivityReset,
};
