"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const loadUserInfo = (payload) => {
    const { userId, clientId, withGroup } = payload || {};
    return {
        type: 'OLY_LOAD_USER_INFO',
        payload: { userId, clientId, withGroup },
    };
};
const loadUserInfoSuccess = (userInfo) => {
    return {
        type: 'OLY_LOAD_USER_INFO_SUCCESS',
        payload: { userInfo },
    };
};
const loadUserInfoError = (error) => {
    return {
        type: 'OLY_LOAD_USER_INFO_ERROR',
        payload: { error },
    };
};
const loadUserInfoReset = () => {
    return {
        type: 'OLY_LOAD_USER_INFO_RESET',
    };
};
const editUserInfo = (payload) => {
    return {
        type: 'OLY_EDIT_USER_INFO',
        payload,
    };
};
const editUserInfoSuccess = (editedUserId) => {
    return {
        type: 'OLY_EDIT_USER_INFO_SUCCESS',
        payload: { editedUserId },
    };
};
const editUserInfoError = (error) => {
    return {
        type: 'OLY_EDIT_USER_INFO_ERROR',
        payload: { error },
    };
};
const editUserInfoReset = () => {
    return {
        type: 'OLY_EDIT_USER_INFO_RESET',
    };
};
const editUserClient = (payload) => {
    return {
        type: 'OLY_EDIT_USER_CLIENT',
        payload,
    };
};
const editUserClientSuccess = () => {
    return {
        type: 'OLY_EDIT_USER_CLIENT_SUCCESS',
    };
};
const editUserClientError = (error) => {
    return {
        type: 'OLY_EDIT_USER_CLIENT_ERROR',
        payload: { error },
    };
};
const editUserClientReset = () => {
    return {
        type: 'OLY_EDIT_USER_CLIENT_RESET',
    };
};
const deleteUserInfoGroup = (payload) => {
    return {
        type: 'OLY_DELETE_USER_INFO_GROUP',
        payload,
    };
};
const deleteUserInfoGroupSuccess = (deletedUserGroupId) => {
    return {
        type: 'OLY_DELETE_USER_INFO_GROUP_SUCCESS',
        payload: { deletedUserGroupId },
    };
};
const deleteUserInfoGroupError = (error) => {
    return {
        type: 'OLY_DELETE_USER_INFO_GROUP_ERROR',
        payload: { error },
    };
};
const deleteUserInfoGroupReset = () => {
    return {
        type: 'OLY_DELETE_USER_INFO_GROUP_RESET',
    };
};
const deleteUserInfoClient = (payload) => {
    return {
        type: 'OLY_DELETE_USER_INFO_CLIENT',
        payload,
    };
};
const deleteUserInfoClientSuccess = (deletedUserClientId) => {
    return {
        type: 'OLY_DELETE_USER_INFO_CLIENT_SUCCESS',
        payload: { deletedUserClientId },
    };
};
const deleteUserInfoClientError = (error) => {
    return {
        type: 'OLY_DELETE_USER_INFO_CLIENT_ERROR',
        payload: { error },
    };
};
const deleteUserInfoClientReset = () => {
    return {
        type: 'OLY_DELETE_USER_INFO_CLIENT_RESET',
    };
};
const deleteUser = (payload) => {
    return {
        type: 'OLY_DELETE_USER',
        payload,
    };
};
const deleteUserSuccess = (deletedUserId) => {
    return {
        type: 'OLY_DELETE_USER_SUCCESS',
        payload: { deletedUserId },
    };
};
const deleteUserError = (error) => {
    return {
        type: 'OLY_DELETE_USER_ERROR',
        payload: { error },
    };
};
const deleteUserReset = () => {
    return {
        type: 'OLY_DELETE_USER_RESET',
    };
};
exports.default = {
    loadUserInfo,
    loadUserInfoSuccess,
    loadUserInfoError,
    loadUserInfoReset,
    editUserInfo,
    editUserInfoSuccess,
    editUserInfoError,
    editUserInfoReset,
    editUserClient,
    editUserClientSuccess,
    editUserClientError,
    editUserClientReset,
    deleteUserInfoGroup,
    deleteUserInfoGroupSuccess,
    deleteUserInfoGroupError,
    deleteUserInfoGroupReset,
    deleteUserInfoClient,
    deleteUserInfoClientSuccess,
    deleteUserInfoClientError,
    deleteUserInfoClientReset,
    deleteUser,
    deleteUserSuccess,
    deleteUserError,
    deleteUserReset,
};
