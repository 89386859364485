"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const reselect_1 = require("reselect");
const userStateSelector = (state) => state.user;
const userAppsStateSelector = (0, reselect_1.createSelector)(userStateSelector, (user) => user.apps);
const loadUserAppsSelector = (0, reselect_1.createSelector)(userAppsStateSelector, (userApps) => userApps.loadUserApps);
const loadUserAppsDataSelector = (0, reselect_1.createSelector)(loadUserAppsSelector, (loadUserApps) => loadUserApps.data);
const loadUserAppsIsLoadingSelector = (0, reselect_1.createSelector)(loadUserAppsSelector, (loadUserApps) => loadUserApps.isLoading);
const loadUserAppsErrorSelector = (0, reselect_1.createSelector)(loadUserAppsSelector, (loadUserApps) => loadUserApps.error);
const pullUserAppsSelector = (0, reselect_1.createSelector)(userAppsStateSelector, (userApps) => userApps.pullUserApps);
const pullUserAppsSuccessSelector = (0, reselect_1.createSelector)(pullUserAppsSelector, (pullUserApps) => pullUserApps.success);
const pullUserAppsIsLoadingSelector = (0, reselect_1.createSelector)(pullUserAppsSelector, (pullUserApps) => pullUserApps.isLoading);
const pullUserAppsErrorSelector = (0, reselect_1.createSelector)(pullUserAppsSelector, (pullUserApps) => pullUserApps.error);
const deleteUserAppSelector = (0, reselect_1.createSelector)(userAppsStateSelector, (userApps) => userApps.deleteUserApp);
const deletedUserAppNameSelector = (0, reselect_1.createSelector)(deleteUserAppSelector, (deleteUserApp) => deleteUserApp.deletedAppName);
const deleteUserAppIsLoadingSelector = (0, reselect_1.createSelector)(deleteUserAppSelector, (deleteUserApp) => deleteUserApp.isLoading);
const deleteUserAppErrorSelector = (0, reselect_1.createSelector)(deleteUserAppSelector, (deleteUserApp) => deleteUserApp.error);
exports.default = {
    loadUserAppsDataSelector,
    loadUserAppsIsLoadingSelector,
    loadUserAppsErrorSelector,
    pullUserAppsSuccessSelector,
    pullUserAppsIsLoadingSelector,
    pullUserAppsErrorSelector,
    deletedUserAppNameSelector,
    deleteUserAppIsLoadingSelector,
    deleteUserAppErrorSelector,
};
