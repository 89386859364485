"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("./constants");
const INITIAL_STATE = {
    currentGroup: null,
    isLoading: false,
    error: false,
};
const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case constants_1.FETCH_CURRENT_GROUP_SUCCESS:
            return {
                currentGroup: action.payload,
                isLoading: false,
                error: false,
            };
        case constants_1.FETCH_CURRENT_GROUP_PENDING:
            return {
                ...state,
                isLoading: action.payload,
            };
        case constants_1.FETCH_CURRENT_GROUP_ERROR:
            return {
                currentGroup: null,
                isLoading: false,
                error: true,
            };
        case constants_1.RESET_CURRENT_GROUP:
            return INITIAL_STATE;
        default:
            return state;
    }
};
exports.default = reducer;
