"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userCoreState = exports.uploadProfilePictureState = void 0;
const redux_1 = require("redux");
exports.uploadProfilePictureState = {
    hasBeenUploaded: false,
    isLoading: false,
    error: null,
};
const uploadProfilePictureReducer = (state = exports.uploadProfilePictureState, action) => {
    switch (action.type) {
        case 'OLY_UPLOAD_PROFILE_PICTURE':
            return { ...exports.uploadProfilePictureState, isLoading: true };
        case 'OLY_UPLOAD_PROFILE_PICTURE_SUCCESS':
            return {
                ...exports.uploadProfilePictureState,
                hasBeenUploaded: true,
            };
        case 'OLY_UPLOAD_PROFILE_PICTURE_ERROR':
            return { ...exports.uploadProfilePictureState, error: action.payload.error };
        case 'OLY_UPLOAD_PROFILE_PICTURE_RESET':
            return exports.uploadProfilePictureState;
        default:
            return state;
    }
};
exports.userCoreState = {
    uploadProfilePicture: exports.uploadProfilePictureState,
};
exports.default = (0, redux_1.combineReducers)({
    uploadProfilePicture: uploadProfilePictureReducer,
});
