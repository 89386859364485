"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserLanguage = void 0;
const getUserLanguage = (currentUserClient) => {
    var _a, _b, _c, _d;
    const langInBrowser = (_b = (_a = window === null || window === void 0 ? void 0 : window.navigator) === null || _a === void 0 ? void 0 : _a.language) === null || _b === void 0 ? void 0 : _b.substring(0, 2);
    const userClientLanguage = (_d = (_c = currentUserClient === null || currentUserClient === void 0 ? void 0 : currentUserClient.information) === null || _c === void 0 ? void 0 : _c.metadata) === null || _d === void 0 ? void 0 : _d.language;
    return userClientLanguage || langInBrowser;
};
exports.getUserLanguage = getUserLanguage;
