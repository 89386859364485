"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateUserClientSettingsError = exports.updateUserClientSettingsSuccess = exports.updateUserClientSettings = exports.loadOneUserClientError = exports.loadOneUserClientSuccess = exports.loadOneUserClient = exports.resetUserClients = exports.setCurrentUserClientId = exports.loadUserClientsError = exports.loadUserClientsSuccess = exports.loadSelectedUserClients = void 0;
const loadSelectedUserClients = (payload) => ({
    type: 'OLY_ENGINE_STORE_LOAD_USER_CLIENTS',
    payload,
});
exports.loadSelectedUserClients = loadSelectedUserClients;
const loadUserClientsSuccess = (userClients) => ({
    type: 'OLY_ENGINE_STORE_LOAD_USER_CLIENTS_SUCCESS',
    payload: { userClients },
});
exports.loadUserClientsSuccess = loadUserClientsSuccess;
const loadUserClientsError = (error) => ({
    type: 'OLY_ENGINE_STORE_LOAD_USER_CLIENTS_ERROR',
    payload: { error },
});
exports.loadUserClientsError = loadUserClientsError;
const setCurrentUserClientId = (clientId) => ({
    type: 'OLY_ENGINE_STORE_SET_CURRENT_USER_CLIENT_ID',
    payload: { clientId },
});
exports.setCurrentUserClientId = setCurrentUserClientId;
const resetUserClients = () => ({
    type: 'OLY_ENGINE_STORE_RESET_USER_CLIENTS',
});
exports.resetUserClients = resetUserClients;
const loadOneUserClient = (payload) => ({
    type: 'OLY_LOAD_ONE_USER_CLIENT',
    payload,
});
exports.loadOneUserClient = loadOneUserClient;
const loadOneUserClientSuccess = (userClient) => ({
    type: 'OLY_LOAD_ONE_USER_CLIENT_SUCCESS',
    payload: { userClient },
});
exports.loadOneUserClientSuccess = loadOneUserClientSuccess;
const loadOneUserClientError = (error) => ({
    type: 'OLY_LOAD_ONE_USER_CLIENT_ERROR',
    payload: { error },
});
exports.loadOneUserClientError = loadOneUserClientError;
const updateUserClientSettings = (payload) => ({
    type: 'OLY_UPDATE_USER_CLIENT_SETTINGS',
    payload,
});
exports.updateUserClientSettings = updateUserClientSettings;
const updateUserClientSettingsSuccess = (settings) => ({
    type: 'OLY_UPDATE_USER_CLIENT_SETTINGS_SUCCESS',
    payload: { settings },
});
exports.updateUserClientSettingsSuccess = updateUserClientSettingsSuccess;
const updateUserClientSettingsError = (error) => ({
    type: 'OLY_UPDATE_USER_CLIENT_SETTINGS_ERROR',
    payload: { error },
});
exports.updateUserClientSettingsError = updateUserClientSettingsError;
exports.default = {
    loadSelectedUserClients: exports.loadSelectedUserClients,
    loadUserClientsSuccess: exports.loadUserClientsSuccess,
    loadUserClientsError: exports.loadUserClientsError,
    setCurrentUserClientId: exports.setCurrentUserClientId,
    resetUserClients: exports.resetUserClients,
    loadOneUserClient: exports.loadOneUserClient,
    loadOneUserClientSuccess: exports.loadOneUserClientSuccess,
    loadOneUserClientError: exports.loadOneUserClientError,
    updateUserClientSettings: exports.updateUserClientSettings,
    updateUserClientSettingsSuccess: exports.updateUserClientSettingsSuccess,
    updateUserClientSettingsError: exports.updateUserClientSettingsError,
};
