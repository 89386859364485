"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteUserIsLoadingSelector = exports.deleteUserErrorSelector = exports.deletedUserIdSelector = exports.deleteUserInfoClientIsLoadingSelector = exports.deleteUserInfoClientErrorSelector = exports.deletedUserInfoClientIdSelector = exports.deleteUserInfoGroupIsLoadingSelector = exports.deleteUserInfoGroupErrorSelector = exports.deletedUserInfoGroupIdSelector = exports.loadUserInfoGroupSelector = exports.editUserClientErrorSelector = exports.editUserClientIsLoadingSelector = exports.editUserClientSuccessSelector = exports.editUserInfoErrorSelector = exports.editUserInfoIsLoadingSelector = exports.editedUserInfoIdSelector = exports.loadUserInfoErrorSelector = exports.loadUserInfoIsLoadingSelector = exports.loadUserInfoSelector = void 0;
const reselect_1 = require("reselect");
const userStateSelector = (state) => state.user;
const userInfoStateSelector = (0, reselect_1.createSelector)(userStateSelector, (user) => user.info);
const loadUserInfoStateSelector = (0, reselect_1.createSelector)(userInfoStateSelector, (info) => info.loadUserInfo);
exports.loadUserInfoSelector = (0, reselect_1.createSelector)(loadUserInfoStateSelector, (info) => info.userInfo);
exports.loadUserInfoIsLoadingSelector = (0, reselect_1.createSelector)(loadUserInfoStateSelector, info => info.isLoading);
exports.loadUserInfoErrorSelector = (0, reselect_1.createSelector)(loadUserInfoStateSelector, info => info.error);
const editUserInfoStateSelector = (0, reselect_1.createSelector)(userInfoStateSelector, (info) => info.editUserInfo);
exports.editedUserInfoIdSelector = (0, reselect_1.createSelector)(editUserInfoStateSelector, (editUserInfo) => editUserInfo.editedUserId);
exports.editUserInfoIsLoadingSelector = (0, reselect_1.createSelector)(editUserInfoStateSelector, editUserInfo => editUserInfo.isLoading);
exports.editUserInfoErrorSelector = (0, reselect_1.createSelector)(editUserInfoStateSelector, editUserInfo => editUserInfo.error);
const editUserClientStateSelector = (0, reselect_1.createSelector)(userInfoStateSelector, (info) => info.editUserClient);
exports.editUserClientSuccessSelector = (0, reselect_1.createSelector)(editUserClientStateSelector, (editUserClient) => editUserClient.success);
exports.editUserClientIsLoadingSelector = (0, reselect_1.createSelector)(editUserClientStateSelector, editUserClient => editUserClient.isLoading);
exports.editUserClientErrorSelector = (0, reselect_1.createSelector)(editUserClientStateSelector, editUserClient => editUserClient.error);
exports.loadUserInfoGroupSelector = (0, reselect_1.createSelector)(exports.loadUserInfoSelector, userInfo => userInfo ? userInfo.groups && userInfo.groups[0] : null);
const deleteUserInfoGroupStateSelector = (0, reselect_1.createSelector)(userInfoStateSelector, (info) => info.deleteUserInfoGroup);
exports.deletedUserInfoGroupIdSelector = (0, reselect_1.createSelector)(deleteUserInfoGroupStateSelector, (deleteUserInfoGroup) => deleteUserInfoGroup.deletedUserGroupId);
exports.deleteUserInfoGroupErrorSelector = (0, reselect_1.createSelector)(deleteUserInfoGroupStateSelector, (deleteUserInfoGroup) => deleteUserInfoGroup.error);
exports.deleteUserInfoGroupIsLoadingSelector = (0, reselect_1.createSelector)(deleteUserInfoGroupStateSelector, (deleteUserInfoGroup) => deleteUserInfoGroup.isLoading);
const deleteUserInfoClientStateSelector = (0, reselect_1.createSelector)(userInfoStateSelector, (info) => info.deleteUserInfoClient);
exports.deletedUserInfoClientIdSelector = (0, reselect_1.createSelector)(deleteUserInfoClientStateSelector, (deleteUserInfoClient) => deleteUserInfoClient.deletedUserClientId);
exports.deleteUserInfoClientErrorSelector = (0, reselect_1.createSelector)(deleteUserInfoClientStateSelector, (deleteUserInfoClient) => deleteUserInfoClient.error);
exports.deleteUserInfoClientIsLoadingSelector = (0, reselect_1.createSelector)(deleteUserInfoClientStateSelector, (deleteUserInfoClient) => deleteUserInfoClient.isLoading);
const deleteUserStateSelector = (0, reselect_1.createSelector)(userInfoStateSelector, (info) => info.deleteUser);
exports.deletedUserIdSelector = (0, reselect_1.createSelector)(deleteUserStateSelector, (deleteUser) => deleteUser.deletedUserId);
exports.deleteUserErrorSelector = (0, reselect_1.createSelector)(deleteUserStateSelector, (deleteUser) => deleteUser.error);
exports.deleteUserIsLoadingSelector = (0, reselect_1.createSelector)(deleteUserStateSelector, (deleteUser) => deleteUser.isLoading);
exports.default = {
    loadUserInfoSelector: exports.loadUserInfoSelector,
    loadUserInfoErrorSelector: exports.loadUserInfoErrorSelector,
    loadUserInfoIsLoadingSelector: exports.loadUserInfoIsLoadingSelector,
    loadUserInfoGroupSelector: exports.loadUserInfoGroupSelector,
    editedUserInfoIdSelector: exports.editedUserInfoIdSelector,
    editUserInfoErrorSelector: exports.editUserInfoErrorSelector,
    editUserInfoIsLoadingSelector: exports.editUserInfoIsLoadingSelector,
    editUserClientSuccessSelector: exports.editUserClientSuccessSelector,
    editUserClientErrorSelector: exports.editUserClientErrorSelector,
    editUserClientIsLoadingSelector: exports.editUserClientIsLoadingSelector,
    deletedUserInfoGroupIdSelector: exports.deletedUserInfoGroupIdSelector,
    deleteUserInfoGroupErrorSelector: exports.deleteUserInfoGroupErrorSelector,
    deleteUserInfoGroupIsLoadingSelector: exports.deleteUserInfoGroupIsLoadingSelector,
    deletedUserInfoClientIdSelector: exports.deletedUserInfoClientIdSelector,
    deleteUserInfoClientErrorSelector: exports.deleteUserInfoClientErrorSelector,
    deleteUserInfoClientIsLoadingSelector: exports.deleteUserInfoClientIsLoadingSelector,
    deletedUserIdSelector: exports.deletedUserIdSelector,
    deleteUserErrorSelector: exports.deleteUserErrorSelector,
    deleteUserIsLoadingSelector: exports.deleteUserIsLoadingSelector,
};
