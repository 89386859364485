import { fonts, colors, defaultFontsLibrary } from './common';

export default {
  colors: {
    ...colors,
    primary: colors.primary,
  },
  fontBold: { fontWeight: 'bold', light: 'regular' },
  fonts: {
    primary: {
      light: fonts.gilroy,
      bold: fonts.gilroy,
    },
    secondary: {
      light: fonts.poppins,
      bold: fonts.poppins,
    },
  },
  fontTypes: {
    ...defaultFontsLibrary,
  },
  button: {
    fontFamily: 'opensans-bold',
    fontSize: 12,
    paddingX: 39,
    paddingY: 13,
    radius: 25,
    primary: {
      backgroundColor: '#e94930',
      borderColor: '#e94930',
      color: '#ffffff',
    },

    secondary: {
      backgroundColor: '#ffffff',
      borderColor: '#e94930',
      color: '#e94930',
    },

    tertiary: {
      backgroundColor: '#7ed321',
      borderColor: '#7ed321',
      color: '#ffffff',
    },
  },

  header: {
    background: '#fff',
    borderBottomColor: colors.grey40,
    borderBottomWidth: 1,
    height: 70,

    menu: {
      activeColor: '#e94930',
      borderColor: '#e94930',
      borderWidth: 2,
      color: colors.grey80,
      fontFamily: 'neuzeit-light',
      fontSize: 15,
    },

    logo: {
      width: 156,
    },
  },

  hamburger: {
    color: colors.grey80,
    radius: 2,
    width: 24,
  },

  onBoarding: {
    content: {
      backgroundColor: '#ffffff',
      boxShadow: '0 20px 31px rgba(0, 0, 0, 0.12)',
      maxWidth: 460,
    },

    overlay: {
      backgroundColor: 'rgba(255, 255, 255, 0.95)',
    },

    dot: {
      borderColor: '#e94930',

      active: {
        backgroundColor: '#e94930',
      },
    },
  },

  activities: {
    cycling: {
      color: '#ffffff',
      gradient: ['#73c2ae', '#2e9989'],
    },
    running: {
      color: '#ffffff',
      gradient: ['#e97730', '#e94930'],
    },
    workout: {
      color: '#ffffff',
      gradient: ['#545454', '#1e1e1c'],
    },
    walking: {
      color: '#ffffff',
      gradient: ['#c86dd7', '#ae23a3'],
    },
    swimming: {
      color: '#ffffff',
      gradient: ['#38aee4', '#1378a7'],
    },
    skiing: {
      color: '#ffffff',
      gradient: ['#b2cffa', '#6694d7'],
    },
  },

  card: {
    backgroundColor: '#ffffff',
    borderColor: colors.grey40,
    borderWidth: 1,
    radius: 10,
    boxShadow: '0 10px 15px 0 transparent',

    badge: {
      borderColor: '#ffffff',
      borderWidth: 3,
      size: 80,
    },

    hover: {
      boxShadow: '0 10px 15px 0 rgba(0, 0, 0, .1)',
    },
  },

  cardContent: {
    title: {
      color: '#1e1e1c',
      fontFamily: 'neuzeit-bold',
    },

    content: {
      color: colors.grey80,
      fontFamily: 'neuzeit-light',
    },

    link: {
      color: '#e94930',
      fontFamily: 'opensans-bold',

      hover: {
        color: '#dd4831',
      },
    },
  },

  cardReward: {
    padding: 23,

    tagNew: {
      color: '#dd4831',
    },

    name: {
      color: colors.black100,
      fontFamily: fonts.primary,
    },

    description: {
      color: colors.grey80,
      fontFamily: fonts.secondary,
    },

    footer: {
      color: colors.grey80,
      fontFamily: fonts.secondary,
      fontSize: 14,
    },

    type: {
      available: {
        color: colors.yellow,
        coinsColor: colors.yellow,
      },

      notEnough: {
        color: colors.grey80,
        coinsColor: colors.grey80,
      },
    },
  },

  cardChallenge: {
    tag: {
      color: '#1e1e1c',
      fontFamily: 'Gilroy',
    },

    title: {
      color: '#1e1e1c',
      fontFamily: 'Gilroy',
    },

    shortDescription: {
      color: colors.grey80,
      fontFamily: 'Poppins',
    },

    element: {
      label: {
        color: colors.grey80,
        fontFamily: 'Poppins',
      },

      value: {
        fontFamily: 'Gilroy',
        fontSize: 12,

        color: {
          default: '#7ed321',
          finish: colors.grey80,
        },
      },
    },
  },

  appsList: {
    color: colors.grey80,
    fontFamily: 'neuzeit-light',

    empty: {
      color: '#f24949',
      fontFamily: 'neuzeit-bold',
    },
  },

  feed: {
    noActivity: {
      title: {
        color: '#1e1e1c',
        fontFamily: 'neuzeit-bold',
      },

      content: {
        color: colors.grey80,
        fontFamily: 'neuzeit-light',
      },
    },

    refreshButton: {
      backgroundColor: '#fea932',
      color: '#ffffff',
      fontFamily: 'neuzeit-light',

      loaded: {
        backgroundColor: '#7ed321',
      },
    },
  },
  modal: {
    backgroundColor: '#ffffff',
    borderColor: colors.grey40,
    borderWidth: 1,
    radius: 10,
    boxShadow: '0 10px 15px 0 transparent',

    cross: {
      border: colors.grey80,
      backgroundColor: colors.grey80,
    },
  },
  overlay: {
    bg: 'rgba(0, 0, 0, 0.6)',
  },

  input: {
    messageFontFamily: 'Poppins',
    fontFamily: 'Gilroy',
    backgroundColor: '#ffffff',
    borderColor: colors.grey40,
    borderWidth: 1,
    labelTextColor: colors.grey100,
    radius: 5,
    textColor: '#000000',
    labelTextSize: 8,
    height: 45,

    focus: {
      borderColor: colors.grey80,
      labelTextColor: '#ffffff',
      labelTextSize: 8,
    },

    disable: {
      backgroundColor: '#ffffff',
      borderColor: colors.grey40,
      labelTextColor: '#ffffff',
    },

    info: {
      borderColor: colors.grey40,
      textColor: colors.grey80,
      iconColor: '#0099FF',
    },
  },

  challenges: {
    card: {
      badge: {
        borderRadius: 50,
      },
    },
  },
  icon: {
    coin: {
      color: '#FFC72F',
    },
  },
  progressionChallenge: {
    fontFamily: fonts.gilroy,
  },
  tokens: {
    border: {
      secondary: colors.grey40,
    },
    surface: {
      secondary: colors.grey40,
    },
  },
};
