"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const common_1 = require("../common");
const theme = {
    colors: common_1.colors,
    fonts: {
        primary: common_1.fonts.gilroy,
        secondary: common_1.fonts.poppins,
    },
    fontTypes: {
        ...common_1.defaultFontsLibrary,
    },
    tag: {
        green: {
            bgColor: common_1.colors.lightGreen,
            borderColor: common_1.colors.green,
            textColor: common_1.colors.green,
        },
        orange: {
            bgColor: common_1.colors.lightOrange,
            borderColor: common_1.colors.orange,
            textColor: common_1.colors.orange,
        },
        blue: {
            bgColor: common_1.colors.lightenBlue,
            borderColor: common_1.colors.blue,
            textColor: common_1.colors.blue,
        },
        grey: {
            bgColor: common_1.colors.grey40,
            borderColor: common_1.colors.darkBlue,
            textColor: common_1.colors.darkBlue,
        },
    },
    button: {
        primary: {
            background: {
                default: common_1.colors.orange,
            },
            color: {
                default: common_1.colors.white,
            },
        },
        secondary: {
            background: {
                default: common_1.colors.grey100,
            },
            color: {
                default: common_1.colors.white,
            },
        },
        dark: {
            background: {
                default: common_1.colors.black,
            },
            color: {
                default: common_1.colors.white,
            },
        },
        danger: {
            background: {
                default: common_1.colors.red,
            },
            color: {
                default: common_1.colors.white,
            },
        },
        action: {
            background: {
                default: common_1.colors.green,
                secondary: common_1.colors.lightGreen,
            },
            color: {
                default: common_1.colors.white,
                secondary: common_1.colors.green,
            },
        },
        basic: {
            background: {
                default: common_1.colors.white,
            },
            color: {
                default: common_1.colors.grey80,
            },
        },
        success: {
            background: {
                default: common_1.colors.green,
            },
            color: {
                default: common_1.colors.white,
            },
        },
        error: {
            background: {
                default: common_1.colors.red,
            },
            color: {
                default: common_1.colors.white,
            },
        },
        grey: {
            background: {
                default: common_1.colors.grey20,
            },
            color: {
                default: common_1.colors.grey100,
            },
        },
        link: {
            background: {
                default: 'inherit',
            },
            color: {
                default: common_1.colors.grey100,
            },
        },
        greyRounded: {
            background: {
                default: common_1.colors.grey40,
            },
            color: {
                default: common_1.colors.grey100,
            },
        },
    },
    field: {
        labelColor: common_1.colors.black,
        subLabelColor: common_1.colors.grey80,
        fontFamily: common_1.fonts.poppins,
    },
    globalNav: {
        text: common_1.colors.white,
        background: common_1.colors.black,
    },
    light: {
        on: {
            borderColor: common_1.colors.lightGreen,
            bgColor: common_1.colors.green,
        },
        off: {
            borderColor: common_1.colors.lightRed,
            bgColor: common_1.colors.red,
        },
        online: {
            borderColor: common_1.colors.lightGreen,
            bgColor: common_1.colors.green,
        },
        offline: {
            borderColor: common_1.colors.lightRed,
            bgColor: common_1.colors.red,
        },
        scheduled: {
            borderColor: common_1.colors.lightOrange,
            bgColor: common_1.colors.orange,
        },
    },
    window: {
        breakpoint: 1024,
    },
    modal: {
        width: '480px',
        height: '560px',
        largeWidth: '640px',
        largeHeight: '640px',
    },
    table: {
        height: '65vh',
    },
};
exports.default = theme;
