"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialState = void 0;
exports.initialState = {
    userFeed: { meta: null, data: null },
    error: false,
};
const reducer = (state = exports.initialState, action) => {
    switch (action.type) {
        case 'OLY_ENGINE_STORE_LOAD_USER_FEED':
            return { ...state, error: exports.initialState.error };
        case 'OLY_ENGINE_STORE_LOAD_USER_FEED_SUCCESS':
            return {
                ...state,
                userFeed: action.payload.userFeed,
            };
        case 'OLY_ENGINE_STORE_LOAD_USER_FEED_ERRROR':
            return {
                ...state,
                error: action.payload.error,
            };
        case 'OLY_ENGINE_STORE_RESET_USER_FEED':
            return exports.initialState;
        default:
            return state;
    }
};
exports.default = reducer;
