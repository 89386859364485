"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteUserActivityErrorSelector = exports.deleteUserActivityIsLoadingSelector = exports.deletedUserActivityIdSelector = exports.deleteUserActivitySelector = exports.editUserActivityErrorSelector = exports.editUserActivityIsLoadingSelector = exports.editedUserActivityIdSelector = exports.editUserActivitySelector = exports.createUserActivityErrorSelector = exports.createUserActivityIsLoadingSelector = exports.createdUserActivitySelector = exports.createUserActivitySelector = exports.replayUserActivityErrorSelector = exports.replayUserActivityIsLoadingSelector = exports.replayedUserActivityIdSelector = exports.replayUserActivitySelector = exports.loadUserActivitiesErrorSelector = exports.loadUserActivitiesIsLoadingSelector = exports.loadUserActivitiesMetaDataSelector = exports.loadUserActivitiesDataSelector = exports.loadUserActivitiesSelector = exports.userActivitiesStateSelector = void 0;
const reselect_1 = require("reselect");
const userStateSelector = (state) => state.user;
exports.userActivitiesStateSelector = (0, reselect_1.createSelector)(userStateSelector, (user) => user.activities);
// LOAD USER ACTIVITIES
exports.loadUserActivitiesSelector = (0, reselect_1.createSelector)(exports.userActivitiesStateSelector, (activities) => activities.loadUserActivities);
exports.loadUserActivitiesDataSelector = (0, reselect_1.createSelector)(exports.loadUserActivitiesSelector, (loadUserActivities) => loadUserActivities.data);
exports.loadUserActivitiesMetaDataSelector = (0, reselect_1.createSelector)(exports.loadUserActivitiesSelector, (loadUserActivities) => loadUserActivities.metadata);
exports.loadUserActivitiesIsLoadingSelector = (0, reselect_1.createSelector)(exports.loadUserActivitiesSelector, (loadUserActivities) => loadUserActivities.isLoading);
exports.loadUserActivitiesErrorSelector = (0, reselect_1.createSelector)(exports.loadUserActivitiesSelector, (loadUserActivities) => loadUserActivities.error);
// REPLAY USER ACTIVITY
exports.replayUserActivitySelector = (0, reselect_1.createSelector)(exports.userActivitiesStateSelector, (activities) => activities.replayUserActivity);
exports.replayedUserActivityIdSelector = (0, reselect_1.createSelector)(exports.replayUserActivitySelector, (replayUserActivity) => replayUserActivity.replayedActivityId);
exports.replayUserActivityIsLoadingSelector = (0, reselect_1.createSelector)(exports.replayUserActivitySelector, (replayUserActivity) => replayUserActivity.isLoading);
exports.replayUserActivityErrorSelector = (0, reselect_1.createSelector)(exports.replayUserActivitySelector, (replayUserActivity) => replayUserActivity.error);
// CREATE USER ACTIVITY
exports.createUserActivitySelector = (0, reselect_1.createSelector)(exports.userActivitiesStateSelector, (activities) => activities.createUserActivity);
exports.createdUserActivitySelector = (0, reselect_1.createSelector)(exports.createUserActivitySelector, (createUserActivity) => createUserActivity.createdActivity);
exports.createUserActivityIsLoadingSelector = (0, reselect_1.createSelector)(exports.createUserActivitySelector, (createUserActivity) => createUserActivity.isLoading);
exports.createUserActivityErrorSelector = (0, reselect_1.createSelector)(exports.createUserActivitySelector, (createUserActivity) => createUserActivity.error);
// EDIT USER ACTIVITY
exports.editUserActivitySelector = (0, reselect_1.createSelector)(exports.userActivitiesStateSelector, (activities) => activities.editUserActivity);
exports.editedUserActivityIdSelector = (0, reselect_1.createSelector)(exports.editUserActivitySelector, (editUserActivity) => editUserActivity.editedActivityId);
exports.editUserActivityIsLoadingSelector = (0, reselect_1.createSelector)(exports.editUserActivitySelector, (editUserActivity) => editUserActivity.isLoading);
exports.editUserActivityErrorSelector = (0, reselect_1.createSelector)(exports.editUserActivitySelector, (editUserActivity) => editUserActivity.error);
// DELETE USER ACTIVITY
exports.deleteUserActivitySelector = (0, reselect_1.createSelector)(exports.userActivitiesStateSelector, (activities) => activities.deleteUserActivity);
exports.deletedUserActivityIdSelector = (0, reselect_1.createSelector)(exports.deleteUserActivitySelector, (deleteUserActivity) => deleteUserActivity.deletedActivityId);
exports.deleteUserActivityIsLoadingSelector = (0, reselect_1.createSelector)(exports.deleteUserActivitySelector, (deleteUserActivity) => deleteUserActivity.isLoading);
exports.deleteUserActivityErrorSelector = (0, reselect_1.createSelector)(exports.deleteUserActivitySelector, (deleteUserActivity) => deleteUserActivity.error);
exports.default = {
    loadUserActivitiesDataSelector: exports.loadUserActivitiesDataSelector,
    loadUserActivitiesMetaDataSelector: exports.loadUserActivitiesMetaDataSelector,
    loadUserActivitiesIsLoadingSelector: exports.loadUserActivitiesIsLoadingSelector,
    loadUserActivitiesErrorSelector: exports.loadUserActivitiesErrorSelector,
    replayedUserActivityIdSelector: exports.replayedUserActivityIdSelector,
    replayUserActivityIsLoadingSelector: exports.replayUserActivityIsLoadingSelector,
    replayUserActivityErrorSelector: exports.replayUserActivityErrorSelector,
    createdUserActivitySelector: exports.createdUserActivitySelector,
    createUserActivityIsLoadingSelector: exports.createUserActivityIsLoadingSelector,
    createUserActivityErrorSelector: exports.createUserActivityErrorSelector,
    editedUserActivityIdSelector: exports.editedUserActivityIdSelector,
    editUserActivityIsLoadingSelector: exports.editUserActivityIsLoadingSelector,
    editUserActivityErrorSelector: exports.editUserActivityErrorSelector,
    deletedUserActivityIdSelector: exports.deletedUserActivityIdSelector,
    deleteUserActivityIsLoadingSelector: exports.deleteUserActivityIsLoadingSelector,
    deleteUserActivityErrorSelector: exports.deleteUserActivityErrorSelector,
};
