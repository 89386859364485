"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetUserFeed = exports.loadUserFeedError = exports.loadUserFeedSuccess = exports.loadUserFeed = void 0;
const loadUserFeed = (payload) => {
    const { userId, clientId, next, apiVersion } = payload || {};
    return {
        type: 'OLY_ENGINE_STORE_LOAD_USER_FEED',
        payload: { userId, clientId, next, apiVersion },
    };
};
exports.loadUserFeed = loadUserFeed;
const loadUserFeedSuccess = (userFeed) => ({
    type: 'OLY_ENGINE_STORE_LOAD_USER_FEED_SUCCESS',
    payload: { userFeed },
});
exports.loadUserFeedSuccess = loadUserFeedSuccess;
const loadUserFeedError = (error) => ({
    type: 'OLY_ENGINE_STORE_LOAD_USER_FEED_ERRROR',
    payload: { error },
});
exports.loadUserFeedError = loadUserFeedError;
const resetUserFeed = () => ({
    type: 'OLY_ENGINE_STORE_RESET_USER_FEED',
});
exports.resetUserFeed = resetUserFeed;
exports.default = {
    loadUserFeed: exports.loadUserFeed,
    loadUserFeedSuccess: exports.loadUserFeedSuccess,
    loadUserFeedError: exports.loadUserFeedError,
    resetUserFeed: exports.resetUserFeed,
};
