export const CHALLENGE_STATUS_COLORS = {
  current: 'green',
  past: 'orange',
  future: 'blue',
};

export const CHALLENGE_RESTRICTION_METHODS = {
  paying: 'paying',
  code: 'code',
  uniqueCode: 'uniqueCode',
};

export const CHALLENGE_CODE_RESTRICTION_METHODS = ['code', 'uniqueCode'];

export const CHALLENGE_STATUS_QUERY = {
  active: 'active',
  finish: 'finish',
  current: 'current',
  next: 'next',
};

export const CHALLENGE_REQUIRED_FIELDS_LABELS = {
  conditions: { label: 'zeus-challenges.form.objectives.conditions.label' },
  type: { label: 'zeus-challenges.form.content.type.label' },
  reference: { label: 'zeus-challenges.form.general.reference.placeholder' },
  slug: { label: 'zeus-challenges.form.general.slug.label' },
  start: { label: 'zeus-challenges.form.general.startdate.label' },
  end: { label: 'zeus-challenges.form.general.enddate.label' },
  title: { label: 'zeus-challenges.form.content.name.label', defaultLang: true },
  excerpt: { label: 'zeus-challenges.form.content.descriptionshort.label', defaultLang: true },
  content: { label: 'zeus-challenges.form.content.descriptionlong', defaultLang: true },
  cover: { label: 'zeus-challenges.form.content.imagecover.label' },
  indexCover: { label: 'zeus-challenges.form.content.imagecard.label' },
  badge: { label: 'zeus-challenges.form.content.imagebadge.label' },
  highlightCta: { label: 'zeus-challenges.form.highlight.previewsubtitle', defaultLang: true },
  onlineDates: { label: 'zeus-users.information.edit.toast.error.validation.field.onlineDates' },
  segmentation: { label: 'zeus-challenges.form.segmentation.toast' },
};

export const CHALLENGE_FORM_STORAGE_KEY = 'challengeFormData';

export const ALLOW_MANUAL = 'allowManual';
export const ATTEMPT = 'attempt';
export const AVAILABLE = 'available';
export const BADGE = 'badge';
export const BONUS_CONFIG = 'bonusConfig';
export const CHALLENGE_TYPE = 'challengeType';
export const CHARITY = 'charity';
export const CLIENT = 'client';
export const COLOR = 'color';
export const CONDITIONS = 'conditions';
export const CONTENT = 'content';
export const COVER = 'cover';
export const COVER_MOBILE = 'coverMobile';
export const CRM = 'crm';
export const CTA_TEXT = 'ctaText';
export const CTA_LINK = 'ctaLink';
export const DEFAULT_TAG_COLOR = null;
export const DISPLAY = 'display';
export const END = 'end';
export const EXCERPT = 'excerpt';
export const EXCLUDED_FROM = 'excludedFrom';
export const FINISHERS = 'finishers';
export const FINISHERS_PUSH = 'finishersPush';
export const HASHTAG = 'hashtag';
export const CRM_HAS_EMAIL_FINISHERS = 'hasEmailFinishers';
export const CRM_HAS_PUSH_FINISHERS = 'hasPushFinishers';
export const CRM_HAS_EMAIL_WINNERS = 'hasEmailWinners';
export const CRM_HAS_EMAIL_REGISTRATION = 'hasEmailRegistration';
export const HEADER_IMAGE = 'headerImage';
export const HEADER_LOGO = 'headerLogo';
export const GOAL = 'goal';
export const GROUP = 'group';
export const HIGHLIGHT = 'highlight';
export const HIGHLIGHT_BACKGROUND_IMAGE = 'highlightBackgroundImage';
export const HIGHLIGHT_CTA = 'highlightCta';
export const ONLINE = 'online';
export const OFFLINE = 'offline';
export const SCHEDULED = 'scheduled';
export const PROMOTE = 'promote';
export const PROMOTE_DESKTOP = 'promoteDesktop';
export const PROMOTE_MOBILE = 'promoteMobile';
export const PROMOTE_LOGO = 'promoteLogo';
export const PROMOTE_TEXT = 'promoteText';
export const POSITION = 'position';
export const INDEX_COVER = 'indexCover';
export const INSTANT_WIN = 'instantWin';
export const LEADERBORD = 'leaderboard';
export const MEMBERS_OF = 'membersOf';
export const MESSAGE = 'message';
export const MIN_DISTANCE = 'minDistance';
export const MIN_DURATION = 'minDuration';
export const MAX_DURATION = 'maxDuration';
export const MIN_POINTS = 'minPoints';
export const MAX_POINTS = 'maxPoints';
export const STEPS = 'steps';
export const MODAL_CONFIRM = 'modalConfirm';
export const MONEY_MULITPLIER = 'moneyMultiplier';
export const NON_WINNERS = 'nonWinners';
export const NO_OBJECTIVE = 'noObjective';
export const OBJECTIVE = 'objective';
export const PARTICIPANTS = 'participants';
export const POINTS = 'points';
export const PRODUCTS = 'products';
export const RACE = 'race';
export const REMAINING = 'remaining';
export const REFERENCE = 'reference';
export const REGISTRATION = 'registration';
export const REPEAT = 'repeat';
export const RESTRICTION_CONFIG = 'restrictionConfig';
export const REWARD_CONDITIONS = 'rewardConditions';
export const REWARD_COVER = 'rewardCover';
export const RIGHT_ILLU = 'rightIllu';
export const REWARD_NAME = 'rewardName';
export const SLUG = 'slug';
export const SOLO = 'solo';
export const START = 'start';
export const TEAM_CAPACITY = 'teamCapacity';
export const TEAM_CHALLENGE = 'teamChallenge';
export const TIMEFRAME = 'timeframe';
export const TITLE = 'title';
export const TOO_SHORT = 'TOO_SHORT';
export const TOTAL = 'total';
export const TYPE = 'type';
export const TYPES = 'types';
export const UNIT = 'unit';
export const USER_ACTION_TYPES = 'userActionsTypes';
export const USERS_AUTO_REGISTRATION = 'usersAutoRegistration';
export const WINNERS = 'winners';
export const PER_DAY = 'perDay';
export const FREQUENCY = 'frequency';
export const FREQUENCY_REPEAT = 'frequencyRepeat';

export const CHALLENGE_FORM_RANKING_TYPES = [GROUP, CHARITY, TEAM_CHALLENGE, RACE];
export const CHALLENGE_FORM_CROSSGROUP_TYPES = [CHARITY, RACE];
export const CHALLENGE_FORM_GROUP_TYPES = [GROUP, CHARITY, TEAM_CHALLENGE];
